var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Unit"},[_c('OrganizationMenu'),_c('div',{staticClass:"wrap"},[_c('el-form',{attrs:{"inline":true}},[_c('el-form-item',{attrs:{"label":"单位类型"}},[_c('span',{staticClass:"unitType",class:[_vm.unitType == null ? 'unitTypeActive': ''],on:{"click":function($event){_vm.unitType = null}}},[_vm._v("不限")]),_vm._l((_vm.unitTypeList),function(item,index){return _c('span',{key:index,staticClass:"unitType",class:[_vm.unitType == item.id ? 'unitTypeActive': ''],on:{"click":function($event){_vm.unitType = item.id}}},[_vm._v(" "+_vm._s(item.label)+" ")])})],2)],1),_c('el-form',{attrs:{"inline":true}},[_c('el-form-item',{attrs:{"label":"项目名称"}},[_c('el-select',{attrs:{"size":"small","placeholder":"请选择项目"},model:{value:(_vm.currentProject),callback:function ($$v) {_vm.currentProject=$$v},expression:"currentProject"}},_vm._l((_vm.projectList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.proName,"value":item.id}})}),1)],1),_c('el-form-item',{attrs:{"label":"单位名称"}},[_c('el-input',{attrs:{"size":"small","placeholder":"请输入单位名称","clearable":""},model:{value:(_vm.unitName),callback:function ($$v) {_vm.unitName=$$v},expression:"unitName"}})],1),_c('el-form-item',{attrs:{"label":"注册所在地"}},[_c('el-cascader',{attrs:{"size":"small","options":_vm.regionData,"props":{
            expandTrigger: 'click',
            checkStrictly: true,
            value: 'name',
            label: 'name',
          },"clearable":""},model:{value:(_vm.region),callback:function ($$v) {_vm.region=$$v},expression:"region"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"size":"small","icon":"el-icon-search"},on:{"click":_vm.getUnitList}},[_vm._v("查询")])],1)],1)],1),_c('div',{staticClass:"wrap-blank"},[_c('el-button',{attrs:{"size":"small","plain":"","type":"primary","icon":"el-icon-plus"},on:{"click":function($event){return _vm.$router.push({
          name: 'UnitInfo',
          query: {
            action: 'add',
            projectId: _vm.currentProject,
            deptId: null,
          },
        })}}},[_vm._v("添加")]),_c('el-button',{attrs:{"type":"danger","icon":"el-icon-delete","size":"small","plain":""},on:{"click":_vm.delUnit}},[_vm._v("删除")])],1),_c('div',{staticClass:"wrap"},[_vm._l((_vm.unitList),function(item,index){return _c('div',{key:index,staticClass:"unit-item"},[_c('el-radio',{attrs:{"label":index},model:{value:(_vm.radio),callback:function ($$v) {_vm.radio=$$v},expression:"radio"}},[_vm._v(_vm._s(""))]),_c('el-descriptions',[_c('template',{slot:"title"},[_c('a',{on:{"click":function($event){return _vm.$router.push({
                name: 'UnitInfo',
                query: {
                  action: 'read',
                  projectId: _vm.currentProject,
                  deptId: item.id,
                },
              })}}},[_vm._v(_vm._s(item.name))])]),_c('el-descriptions-item',{attrs:{"label":"统一社会信用代码"}},[_vm._v(_vm._s(item.socialCreditCode))]),_c('el-descriptions-item',{attrs:{"label":"法定代表人"}},[_vm._v(_vm._s(item.legalEntity))]),_c('el-descriptions-item',{attrs:{"label":"注册所在地(未提供)"}},[_vm._v(_vm._s(item.masterMobile))]),_c('el-descriptions-item',{attrs:{"label":"营业执照号"}},[_vm._v(_vm._s(item.businessNo))]),_c('el-descriptions-item',{attrs:{"label":"单位类型"}},[_vm._v(_vm._s(item.isDevop))]),_c('el-descriptions-item',{attrs:{"label":"注册资本"}},[_vm._v(_vm._s(item.registeredCapital))]),_c('el-descriptions-item',{attrs:{"label":"联系人"}},[_vm._v(_vm._s(item.unitLeader))]),_c('el-descriptions-item',{attrs:{"label":"手机号"}},[_vm._v(_vm._s(item.phone))]),_c('el-descriptions-item',{attrs:{"label":"成立日期"}},[_vm._v(_vm._s(item.establishTime))])],2)],1)}),(_vm.unitList.length)?_c('el-pagination',{attrs:{"layout":"total, sizes, prev, pager, next","page-size":_vm.pageSize,"current-page":_vm.pageNum,"page-sizes":[2, 5, 10, 20, 50],"total":_vm.totals},on:{"update:pageSize":function($event){_vm.pageSize=$event},"update:page-size":function($event){_vm.pageSize=$event},"update:currentPage":function($event){_vm.pageNum=$event},"update:current-page":function($event){_vm.pageNum=$event},"current-change":_vm.getUnitList,"size-change":_vm.getUnitList}}):_vm._e(),(!_vm.unitList.length)?_c('el-empty',{attrs:{"description":"暂无数据"}}):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }