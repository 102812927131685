<template>
  <div class="Unit">
    <OrganizationMenu />

    <!-- 筛选项 -->
    <div class="wrap">
      <el-form :inline="true">
        <el-form-item label="单位类型">
          <span class="unitType" @click="unitType = null" :class="[unitType == null ? 'unitTypeActive': '']">不限</span>
          <span
            class="unitType"
            :class="[unitType == item.id ? 'unitTypeActive': '']"
            v-for="(item, index) in unitTypeList"
            :key="index"
            @click="unitType = item.id"
          >
            {{ item.label }}
          </span>
        </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="项目名称">
          <el-select size="small" v-model="currentProject" placeholder="请选择项目">
            <el-option
              v-for="item in projectList"
              :key="item.id"
              :label="item.proName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单位名称">
          <el-input
            size="small"
            v-model="unitName"
            placeholder="请输入单位名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="注册所在地">
          <el-cascader
            size="small"
            v-model="region"
            :options="regionData"
            :props="{
              expandTrigger: 'click',
              checkStrictly: true,
              value: 'name',
              label: 'name',
            }"
            clearable
          >
          </el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button size="small" icon="el-icon-search" @click="getUnitList"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <!-- 功能按钮 -->
    <div class="wrap-blank">
      <el-button
        size="small"
        plain
        type="primary"
        icon="el-icon-plus"
        @click="
          $router.push({
            name: 'UnitInfo',
            query: {
              action: 'add',
              projectId: currentProject,
              deptId: null,
            },
          })
        "
        >添加</el-button
      >
      <el-button
        type="danger"
        icon="el-icon-delete"
        size="small"
        plain
        @click="delUnit"
        >删除</el-button
      >
      <!-- <el-button
        type="success"
        size="small"
        plain
        icon="el-icon-download"
        >导出当前数据</el-button
      >
      <el-button
        type="success"
        size="small"
        plain
        icon="el-icon-download"
        >导出全部数据</el-button
      > -->
    </div>

    <!-- 数据列表 -->
    <div class="wrap">
      <div
        class="unit-item"
        v-for="(item, index) in unitList"
        :key="index"
      >
        <el-radio v-model="radio" :label="index">{{ "" }}</el-radio>
        <el-descriptions>
          <template slot="title">
            <a
              @click="
                $router.push({
                  name: 'UnitInfo',
                  query: {
                    action: 'read',
                    projectId: currentProject,
                    deptId: item.id,
                  },
                })
              "
              >{{ item.name }}</a
            >
          </template>
          <el-descriptions-item label="统一社会信用代码">{{
            item.socialCreditCode
          }}</el-descriptions-item>
          <el-descriptions-item label="法定代表人">{{
            item.legalEntity
          }}</el-descriptions-item>
          <el-descriptions-item label="注册所在地(未提供)">{{
            item.masterMobile
          }}</el-descriptions-item>
          <el-descriptions-item label="营业执照号">{{
            item.businessNo
          }}</el-descriptions-item>
          <el-descriptions-item label="单位类型">{{
            item.isDevop
          }}</el-descriptions-item>
          <el-descriptions-item label="注册资本">{{
            item.registeredCapital
          }}</el-descriptions-item>
          <el-descriptions-item label="联系人">{{
            item.unitLeader
          }}</el-descriptions-item>
          <el-descriptions-item label="手机号">{{
            item.phone
          }}</el-descriptions-item>
          <el-descriptions-item label="成立日期">{{
            item.establishTime
          }}</el-descriptions-item>
        </el-descriptions>
      </div>

      <el-pagination
        v-if="unitList.length"
        layout="total, sizes, prev, pager, next"
        :page-size.sync="pageSize"
        :current-page.sync="pageNum"
        :page-sizes="[2, 5, 10, 20, 50]"
        :total="totals"
        @current-change="getUnitList"
        @size-change="getUnitList"
      >
      </el-pagination>

      <el-empty description="暂无数据" v-if="!unitList.length"></el-empty>
    </div>

  </div>
</template>

<style lang="less" scope>
.Unit{
  .el-form-item {
    margin-bottom: 0;
  }
  .unit-item {
    margin-bottom: 20px;
    border-bottom: 1px dashed #d5d9e4;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .unitType {
    font-weight: 700;
    color: #409eff;
    padding: 5px 5px;
  }
  .unitTypeActive {
    color: #fff;
    background-color: #409eff;
    border-radius: 5px;
  }
}
</style>

<script>
import OrganizationMenu from "@/components/OrganizationMenu.vue";

export default {
  name: "Unit",
  components: {
    OrganizationMenu,
  },
  data() {
    return {
      regionData: null,
      region: [],

      projectList: [],
      currentProject: null,

      unitTypeList: [],
      unitType: null,
      unitName: null,

      unitList: [],
      radio: null,

      pageSize: 5,
      pageNum: 1,
      totals: 0

    };
  },
  mounted() {
    this.getRegionData(); // 加载省市区数据
    this.getProjectList(); // 加载项目列表数据
    this.getUnitTypeList(); // 加载单位类型数据
  },
  watch: {
    currentProject: function (val) {
      console.log('watch currentProject =>', val)
      this.getUnitList() // 加载单位列表数据
    },
    unitType: function (val) {
      this.getUnitList() // 加载单位列表数据
    }
  },
  methods: {
    // 加载省市区数据
    getRegionData: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "get",
          url: "data/region.json",
        })
        .then((res) => {
          that.regionData = res.data;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },

    // 加载项目列表数据
    getProjectList: function () {
      var that = this;
      that.radio = null;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        proName: "",
        projectSysNo: "",
        proAddress: "",
        pageNum: 1,
        pageSize: 10,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/project/projectSearch",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.projectList = res.data.projectList;
          if (res.data.projectList.length) {
            that.currentProject = res.data.projectList[0].id
          }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },

    // 加载单位类型数据
    getUnitTypeList: function () {
      var that = this;
      that.radio = null;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "get",
          url: "api/admin/dict/type/company_type",
        })
        .then((res) => {
          console.log(res);
          that.unitTypeList = res.data;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },

    // 加载单位列表数据
    getUnitList: function () {
      var that = this;
      that.radio = null;
      if (that.region.length) {
        var address = that.region.join('')
      } else {
        var address = ''
      }
      var data = {
        companyType: that.unitType,
        companyName: that.unitName,
        address: address,
        projectId: that.currentProject,
        pageNum: that.pageNum,
        pageSize: that.pageSize,
      }
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/dept/InquireCompany",
          data: data
        })
        .then((res) => {
          console.log('getUnitList', res);
          if (res.data.depts != null) {
            that.unitList = res.data.depts;
          } else {
            that.unitList = [];
          }

          that.totals = res.data.totals
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 删除单位
    delUnit: function () {
      var that = this;
      if (that.radio == null) {
        that.$alert("请先选择要删除的单位", "提示", { showClose: false });
        return;
      }
      var unit = that.unitList[that.radio];
      that
        .$confirm(`此操作将删除 ${unit.name}, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          var data = {
             deptId: unit.id,
          };
          that.loading = that.$loading({
            lock: true,
            text: "Loading...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          that
            .$axiosAdmin({
              method: "post",
              url: "api/admin/dept/delete",
              data: JSON.stringify(data),
            })
            .then((res) => {
              that.loading.close();
              console.log("成功=>", res);
              if (res.code == 0) {
                that.$notify({
                  title: "操作成功",
                  message: "已成功删除单位",
                  type: "success",
                });
                that.getUnitList();
              } else {
                that.$notify({
                  title: "警告",
                  message: "单位删除失败",
                  type: "warning",
                });
              }
            })
            .catch((err) => {
              console.log("失败=>", err);
              that.loading.close();
            });
        })
        .catch(() => {
          that.$notify({
            title: "警告",
            message: "已取消删除，请谨慎操作。",
            type: "warning",
          });
        });
    },
  },
};
</script>
