<template>
  <div class="organization-menu">
    <div class="main-menu">
      <router-link
        v-if="menuPermission.Organization.Unit"
        :to="{ name: 'Unit' }"
        :class="[
          $route.name == 'Unit' ? 'child-menu-active' : '',
          $route.name == 'UnitInfo' ? 'child-menu-active' : '',
        ]">参建单位
      </router-link>
      <router-link
        v-if="menuPermission.Organization.Department"
        :to="{ name: 'Department' }"
        :class="[
          $route.name == 'Department' ? 'child-menu-active' : '',
          $route.name == 'DepartmentInfo' ? 'child-menu-active' : '',
        ]">部门/班组
      </router-link>
      <router-link
        v-if="menuPermission.Organization.Personnel"
        :to="{ name: 'Personnel' }"
        :class="[
          $route.name == 'Personnel' ? 'child-menu-active' : '',
          $route.name == 'PersonnelInfo' ? 'child-menu-active' : '',
        ]">员工花名册
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrganizationMenu',
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
    menuPermission: function () {
      return JSON.parse(localStorage.getItem("menuPermission"));
    },
  },
}
</script>

<style lang="less" scoped>
@link-color: #2c85d8;

.organization-menu {
  width: 1200px;
  margin: 0 auto;
  a {
    display: inline-block;
    padding: 10px 20px;
  }
}
</style>
